import Link from 'next/link'
import React, {useContext, useState} from "react"
import styles from './loginForm.module.css'
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {login, redirect} from './api'
import {parseCookies} from 'nookies'
import {FingerprintContext} from "../../context";
import {useRouter} from 'next/router'
import ValidationContainer from "../registration/validationContainer";
import {redClick} from "../../../utils/redtrack";

const schema = yup.object().shape({
    username: yup.string().required("Please enter your username, or email if you didn't\n" +
        "                                create one yet.").min(5, "Username is too short"),
    password: yup.string().required("Please enter your password.").min(5, "Password is too short"),
});

export default function LoginForm() {
    const router = useRouter();
    const {email} = router.query;
    const {register, handleSubmit, errors, setError, setValue, getValues} = useForm({
        defaultValues: {
            username: email
        },
        resolver: yupResolver(schema)
    });

    console.log(getValues("username"), email);
    if (getValues("username") == "" && email !== "") {
        setValue("username", email);
    }

    const deviceIdContext = useContext(FingerprintContext);
    const [showPwd, setShowPwd] = useState(false);
    const togglePassword = (e) => {
        e.preventDefault();
        setShowPwd(!showPwd);
    }

    const onSubmit = async data => {
        const response = await login(data.username, data.password, deviceIdContext.str);
        console.log(response);
        if (response.status) {
            const cookies = parseCookies();
            const msId = cookies["irs-msclkid"];
            await redClick(response.userUid,"Login");
            await redirect(response.token, msId && msId !== "" && msId !== "null" ? `&${msId}` : "")
        } else {
            setError("password", {message: response.error, type: "login error"});
        }
    }

    return (
        <div className="contentdiv" id="login-form">
            {Object.keys(errors).length > 0 &&
            <div className={`${styles.bsCallout} ${styles.bsCalloutWarning}`}>
                <h4>Please correct the errors below!</h4>
            </div>}

            <div style={{color: "#999"}}>
                If you didn't create a username, please use your email address instead.
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="content">
                    <div>
                        <div className={`${styles.inputdiv} inputdiv ${errors.username ? styles.error : ""}`}>
                            <input type="text" placeholder="Username" name="username" ref={register}/>
                            <span><i className="fa fa-user" aria-hidden="true"></i></span>
                        </div>
                        {errors.username &&
                        <ul className={`${styles.errorsList} ${styles.list} filled`} aria-hidden="false">
                            <li className="required">{errors.username.message}
                            </li>
                        </ul>}

                    </div>
                    <div className="content">
                        <div className={`${styles.inputdiv} inputdiv ${errors.password ? styles.error : ""}`}>
                            <input type={showPwd ? "text" : "password"} placeholder="Password" name="password"
                                   ref={register}
                            />
                            <span><i className="fa fa-lock" aria-hidden="true"></i></span>
                            <span style={{"paddingRight": "15px"}}>
                                <a href="" onClick={togglePassword}>
                                    <i className={"fa fa-eye" + (showPwd ? "" : "-slash")}
                                       aria-hidden="true"/></a>
                            </span>
                        </div>
                        {errors.password &&
                        <ul className={`${styles.errorsList} ${styles.list} filled`} aria-hidden="false">
                            <li className="required">{errors.password.message}
                            </li>
                        </ul>}
                    </div>
                </div>
                <div className="contentfooter">
                    <button type="submit" className="submitbtn">
                        Submit
                    </button>
                    <Link href={"/forgot-password?email=" + getValues("username")}>
                        <a href={"/forgot-password?email=" + getValues("username")}>
                            Forgot Username or Password?</a>
                    </Link>
                    <a id="urlLink" style={{display: "none"}} href="https://google.com">&nbsp;</a>
                    <iframe src="about:blank" style={{display: "none"}} width="1" height="1" id="goif"></iframe>
                </div>
            </form>

        </div>
    )
}
