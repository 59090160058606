import axios from "axios"

export const login = async (username, password, deviceId) => {
    try {
        const response = await axios.post("/api/login", {
            username, password,
            pushnamiSubscriberId: localStorage.getItem("pushnamiSubscriberId"),
            source: window.location.hostname,
            device_id: deviceId,
        });

        if (response.status === 200) {
            console.log("Returning successful login");
            return {
                status: response.data.status,
                error: response.data.error,
                token: response.data.token,
                userUid: response.data.userUid,
            }
        } else {
            throw Error("Server failed to respond, status code: " + response.statusText + ". Data: " + JSON.stringify(response.data))
        }
    } catch (e) {
        return {
            status: false,
            error: "Server error. Please try again in a minute."
        }
    }
}

export const redirect = async (token, add) => {
    const engine = process.env.NEXT_PUBLIC_ENGINE_URL;

    let redirectUrl = `${engine}/land/${window.location.hostname}/?t=${token}${add}`

    const osUserId = localStorage.getItem('OneSignalUserId');
    if (osUserId && osUserId !== "") {
        redirectUrl += "&os_disabled=1"
    }

    const urlLinkEl = document.getElementById("urlLink");
    urlLinkEl.setAttribute("href", redirectUrl);

    try {
        eventFire(document.getElementById('urlLink'), "mousedown");
    } catch (e) {

    }

    const goIfr = document.getElementById("goif");

    window.setTimeout(function () {
        goIfr.setAttribute("src", urlLinkEl.getAttribute("href"));
    }, 200);

    window.setTimeout(function () {
        window.location.href = urlLinkEl.getAttribute("href");
    }, 1500);

    window.setTimeout(function () {
        window.location.href = redirectUrl;
    }, 5000);
}


const eventFire = (el, etype) => {
    if (el.fireEvent) {
        el.fireEvent('on' + etype);
    } else {
        var evObj = document.createEvent('Events');
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
    }
}
