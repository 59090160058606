import React from "react";
import styles from './registration.module.css';

export default function ValidationContainer(props) {
    const error = props.error;
    if(props.showIf) {
        return (
            <div className={`inputdiv ${error ? "error-container-error" : props.data ? "error-container-ok" : ""}`}>
                {props.children}
            </div>
        )
    }

    return <></>

}

